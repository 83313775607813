import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This was originally posted on my `}<a parentName="p" {...{
        "href": "https://theflying.dev/digital-garden/command-line-shortcuts/"
      }}>{`theflyingdev`}</a>{` page.`}</p>
    <div className="flex justify-center mt-12">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/yZZmW3KTYr4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </div>
    <Note mdxType="Note">
When I recorded this video, I thought the shortcut <code>CTRL+U</code> was deleting the line, but it's just cutting the whole line, you can paste the text with <code>CTRL+Y</code>.
    </Note>
    <h2 {...{
      "id": "shortcuts-for-moving-around",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#shortcuts-for-moving-around",
        "aria-label": "shortcuts for moving around permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Shortcuts for moving around`}</h2>
    <p>{`Let's be honest how many times do we write a command, just to notice that around the beginning of the line there is a mistake and we delete everything and type the command again? These shortcuts will help you move the cursor easier without the need to delete the whole thing.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + A`}</inlineCode>{` - Moves the cursor to the beginning of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + E`}</inlineCode>{` - Moves the cursor to the end of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + arrow keys`}</inlineCode>{` - Moves the cursor from word to word `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ALT + B`}</inlineCode>{` - Moves the cursor back from word to word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ALT + F`}</inlineCode>{` - Moves cursors forward from word to word`}</li>
    </ul>
    <h2 {...{
      "id": "cutting-and-pasting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cutting-and-pasting",
        "aria-label": "cutting and pasting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cutting and pasting`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + W`}</inlineCode>{` - Cut the word left of the cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + K`}</inlineCode>{` - Cut the entire text at the right of the cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + U`}</inlineCode>{` - Cut the entire line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + Y`}</inlineCode>{` - Paste the previous cut text`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ALT  + W`}</inlineCode>{` - Cut the entire text at the left of the cursor`}</li>
    </ul>
    <h2 {...{
      "id": "text-manipulation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#text-manipulation",
        "aria-label": "text manipulation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Text manipulation`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + T`}</inlineCode>{` - Swap two letters between the cursor`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`this`}</inlineCode>{` if the cursor is between the `}<strong parentName="li">{`h`}</strong>{` and `}<strong parentName="li">{`i`}</strong>{` it turns into `}<inlineCode parentName="li">{`tihs`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ESC + T`}</inlineCode>{` - Swap two words between the cursor`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`this text`}</inlineCode>{` will become `}<inlineCode parentName="li">{`text this`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ESC + U`}</inlineCode>{` - Uppercases text right of the cursor - moves cursor to the end of word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + Q`}</inlineCode>{` - Deletes text from line - clears line only`}</li>
    </ul>
    <h2 {...{
      "id": "other-useful-shortcuts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-useful-shortcuts",
        "aria-label": "other useful shortcuts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other useful shortcuts`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + L`}</inlineCode>{` - Clears the terminal -> No need to write `}<inlineCode parentName="li">{`clear`}</inlineCode>{` all the time!`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + R`}</inlineCode>{` - Reverse search your history for a term`}
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`CTRL + R`}</inlineCode>{` again to cycle through results`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`!!`}</inlineCode>{` Repeat the last command used in the terminal`}</li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`sudo !!`}</inlineCode>{` to repeat the last command with `}<inlineCode parentName="li">{`sudo`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "get-the-cheatsheet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#get-the-cheatsheet",
        "aria-label": "get the cheatsheet permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get the cheatsheet`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/48755987d3254c93878e3da34038a330/ac7a9/terminal_shortcuts_light.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBUlEQVQoz1WPW2sTYRCG8yO9Uii9sIqotV6oFBH/gxeiNKWaRGhB/QkibaNFwYsekqbpJpvmsLvJJnv4DjPzHfbTrS3ow3s38zDvVACAABWQAiLAMkgqmKpTT40j8i6UN1DBjPB6eh0AqHApng62b3ZfL3Xf3PE21/zGw35tuV9dGlSXexsfZt/vDt7f6r2919t67Dce+Y3bveqNs1cbky8OTQUQgywcJqORWrTng1bst2L/MDg/jrxuOpoTGyfjUTLqZpOjqXc87Z1M+510GFOOAKXsUDkmnESUEoSUXEgmjNaucNqaAsgpo0kJxgXjPGckwRhT1gZCJ8mRdc4pBphxSDmm3Erl/iLKqQYl5pmV2pWLzhpbyoR0lg33k/ZBft6cnzbj9l7c2o1Pvs6Oejz8kXnNeetgcfpt0dmP27txa3/RbmadDhsTYMWirkV7K151rV9fv9h5Ofz07GJ71a8/8GufZz+f+zv3++9W/fr6YOfF8OOT4faa31jxNmvRnkVdAQCrjVPW6cIw1AwsJyd12c3aQltXuEJpw8AKcubqlavaoBQh2CTleRZEYRCF4TTKOVNGKWs1YxZgkaWjyTicRkEUxsmcCa6MLmX8dWi4cIUjxDRN8jzPSlIppTGm0NpZK4RI0zTLsiRJhBD6ksvL8QIBSSkiUv9ARIhI16j/IaI/8m8RIVJTzDD2bwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terminal shortcuts light",
            "title": "terminal shortcuts light",
            "src": "/static/48755987d3254c93878e3da34038a330/0eb09/terminal_shortcuts_light.png",
            "srcSet": ["/static/48755987d3254c93878e3da34038a330/c59a0/terminal_shortcuts_light.png 125w", "/static/48755987d3254c93878e3da34038a330/86700/terminal_shortcuts_light.png 250w", "/static/48755987d3254c93878e3da34038a330/0eb09/terminal_shortcuts_light.png 500w", "/static/48755987d3254c93878e3da34038a330/e9985/terminal_shortcuts_light.png 750w", "/static/48755987d3254c93878e3da34038a330/1263b/terminal_shortcuts_light.png 1000w", "/static/48755987d3254c93878e3da34038a330/ac7a9/terminal_shortcuts_light.png 1920w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0b7ec1fd680defb8ced75c0e7280e7e9/ac7a9/terminal_shortcuts_dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQElEQVQoz1WSa2+acBSH+RpDTZUKCAJ/QEBRFBBFFBsurriK4G3zhu3aLE32Vfq6tyUbTbYX+3ybTbZlyfPilzz55ZycHAjPaGhGK2bUIqyirxmFNQQYBa2HsAYim4WGiTAG+tf+Ac9oEJnV7oj+D3qYUnbKOak8TqvnKWG/4P1vpH1dMJ/xwXfSTgX/qOTgKz38SdmfsV4eVqESrHe58VCKzkphVN/OGrtY2S31y4V6GIsrBXH6QmhL07GwWrQO82Yya+7D6qZ16qKwCuGwzlLveCni+akurg1xY0ibTnVbp+cVPAR5hwchz0ybzNKobo3qtlvb6cIGnHoY3DpOZsAFzceAiHQ56SiX3eZVt3nVknZiKS6dOFQlorlYqWws7VoRNxK94IkFhXg43IJQWLPws4hwJ6gzZ4IFCJZMsKDPV0xgYe6oYIWkMyHcOX2+ZMdLJogJP8bcHtI/rl2A1VtskHL+A/CeapNHJX4Ux4+0+0x7+6J1B9wvwHsC/lPt4qEe3rOje8p9Yf1b3C68HkwtoyOKmwlgqcmJJidqLVHELUfMy3mXLocUGcncB1VOmtJOpJcMNqWxuIz4eEaFim9UDvfFetSW3o+MG9+48fRrU1gr5Rl74oiVicSGtrwPep98/eOofTNsJKa44REPg1Xobb4to75QjlpgMWgkvdrOkvdWfd+urEHeEcmJSE47wrpfT6z6fqAcDHHdYlbcqX8sV7I6mTXInEnmukT2H2TOLOUM8hi6xH+q84rx+8N+AXevr3Weanm+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terminal shortcuts dark",
            "title": "terminal shortcuts dark",
            "src": "/static/0b7ec1fd680defb8ced75c0e7280e7e9/0eb09/terminal_shortcuts_dark.png",
            "srcSet": ["/static/0b7ec1fd680defb8ced75c0e7280e7e9/c59a0/terminal_shortcuts_dark.png 125w", "/static/0b7ec1fd680defb8ced75c0e7280e7e9/86700/terminal_shortcuts_dark.png 250w", "/static/0b7ec1fd680defb8ced75c0e7280e7e9/0eb09/terminal_shortcuts_dark.png 500w", "/static/0b7ec1fd680defb8ced75c0e7280e7e9/e9985/terminal_shortcuts_dark.png 750w", "/static/0b7ec1fd680defb8ced75c0e7280e7e9/1263b/terminal_shortcuts_dark.png 1000w", "/static/0b7ec1fd680defb8ced75c0e7280e7e9/ac7a9/terminal_shortcuts_dark.png 1920w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      